@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  display: none;
}

table td, table th{
  border: 1px solid rgba(0, 0, 0, 0.4);
  width: 100%;
  min-width: 80px;
}